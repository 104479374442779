.NavbarParent {
    font-family: Inter;
    font-style: normal;
    /*  */
    width: 100%;
    /* position: sticky;
    top: 0;
    left: 0; */
    /* width:90vw; */
    /*  */
}

.rightlinks_parent{
    /* background-color:red !important; */
    width:30%;
    margin:0%;

    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: right;
    padding:0%;
    padding-left:2%;
    /* ^^align to right on purpose */
}

.links {
    color: rgba(255, 255, 255, 1);
    /*  */
    /* background-color: purple; */
    /* font-size: 1.5em; */
    margin: 0% 4%;
}
/* .linksright{
    margin: 0% 20%;
} */

@media screen and (max-width: 1199px) and (min-width:992px) {
    .links {
        margin: 0% 0%;
        /* font-size: 1.5em; */
    }
}