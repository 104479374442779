.WhatWeDoParent {
    font-family: Inter;
    font-style: normal;
    /*  */
    margin: 0%;
    margin-top: 5%;
    padding: 0% 5%;
    /*  */
    /* background-color: darkmagenta; */
    /*  */
    min-height: 110vh;
    /*  */
    color: white;
}

.WhatWeDoHead {
    color: rgba(107, 199, 39, 1);
    font-size: calc(1.5em + 1.0vw);
    /* font-size: 2.5em; */
    font-weight: bold;
}

.WhatWeDoParagraph {
    font-size: calc(1em + 0.2vw);
    text-align: center;
    margin: 0%;
    padding: 0%;
}

.WhatWeDoGrid {
    /* background-color:green; */
    background-color: rgba(36, 36, 36, 1);
    /*  */
    margin: 0%;
    /* margin-top: 5%; */
    /* padding:4% 4%; */
    padding: 0%;
    width: 85%;
    /*  */
    /* border:1px solid black; */
    /*  */
    color: white;
}

.WhatWeDoGridMember {
    border: 1px solid white;
    border-radius: 10px 0px 0px 0px;
    padding: 4% 4%;
    /* display:flex; */
}

.WhatWeDoGridMember1 {
    border-radius: 10px 0px 0px 0px;
}

.WhatWeDoGridMember2 {
    border-radius: 0px 10px 0px 0px;
}

.WhatWeDoGridMember3 {
    border-radius: 0px 0px 0px 10px;
}

.WhatWeDoGridMember4 {
    border-radius: 0px 0px 10px 0px;
}

.WhatWeDoGridMemberIcon {
    /* background-color: purple; */
    float: left;
    width: 12%;
    margin-right: 5%;
    margin-left: 4%;
}

.WhatWeDoGridMemberContent {
    /* background-color: orange; */
    float: left;
    width: 75%;
    margin: 0%;
    padding: 0%;
    color: rgba(255, 255, 255, 1);
}

.GridFillers {
    display: none;
}

/*! lg + md */

@media screen and (min-width:768px) and (max-width: 1199px) {
    .WhatWeDoParent {
        min-height: 70vh;
        /* height: auto; */
        margin-top: 5%;
    }
    /* .WhatWeDoHead {
        font-size: calc(2.5em + 1.5vw);
        font-size: 2.5em;
    } */
    /* .WhatWeDoParagraph {
        font-size: calc(1em + 1.5vw);
        font-size: 1em;
        text-align: justify;
    } */
    .WhatWeDoGrid {
        width: 90%;
    }
    .WhatWeDoGridMember {
        /* background-color:red; */
        /*  */
        padding: 4% 2%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
    .WhatWeDoGridMemberIcon {
        /* background-color:red; */
        /*  */
        float: right;
        width: 15%;
        margin-bottom: 0%;
        margin-right: 10%;
    }
    .WhatWeDoGridMemberIcon3 {
        width: 18%;
    }
    .WhatWeDoGridMemberContent {
        float: none;
        width: auto;
        /*  */
        padding: 0% 5%;
    }
    /* .WhatWeDoGridMemberHead {
        font-size: calc(1.2em + 1.5vw);
        font-size:1.8em;
    } */
    .WhatWeDoGridMemberParagraph {
        background-color: none;
        /* background: red; */
        /*  */
        /* font-size: calc(1em + 1.0vw); */
        /* font-size: 2em; */
        /*  */
        /* height:200px;
        overflow:hidden;
        -webkit-line-clamp: 3; */
        /* white-space:nowrap; */
        /* text-overflow: ellipsis; */
        /*  */
        /* display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden; */
        /*  */
        /* text-align: justify; */
    }
}

/*! md */

@media screen and (min-width:768px)and (max-width:991px) {
    .WhatWeDoParent {
        min-height: 130vh;
        margin-top: 5%;
    }
    .WhatWeDoGrid {
        background-color: rgba(36, 36, 36, 0);
    }
    .GridFillers {
        display: block;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    /* .GridFillers1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 5%;
    }
    .GridFillers2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 5%;
    }
    .GridFillers3 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 5%;
    }
    .GridFillers4 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 5%;
    } */
    .WhatWeDoGridMemberIcon {
        display: none;
    }
    .WhatWeDoGridFillersIcon {
        /* background-color: red; */
        /* width: 20%; */
        width: 30%;
    }
    .WhatWeDoGridMember1 {
        border-radius: 10px 10px 0px 10px;
    }
    .WhatWeDoGridMember2 {
        border-radius: 0px 10px 10px 0px;
    }
    .WhatWeDoGridMember3 {
        border-radius: 10px 0px 0px 10px;
    }
    .WhatWeDoGridMember4 {
        border-radius: 0px 10px 10px 10px;
    }
}

/*! sm */

@media screen and (min-width:576px) and (max-width:767px) {
    .WhatWeDoParent {
        min-height: 130vh;
        margin-top: 5%;
    }
    .WhatWeDoGrid {
        background-color: rgba(36, 36, 36, 0);
    }
    .WhatWeDoGridMember {
        margin-top: 5%;
    }
    .WhatWeDoGridMember1 {
        border-radius: 10px 10px 10px 10px;
        margin-top: 0%;
    }
    .WhatWeDoGridMember2 {
        border-radius: 10px 10px 10px 10px;
    }
    .WhatWeDoGridMember3 {
        border-radius: 10px 10px 10px 10px;
    }
    .WhatWeDoGridMember4 {
        border-radius: 10px 10px 10px 10px;
    }
}

/*! xsm */

@media screen and (max-width:575px) {
    .WhatWeDoParent {
        min-height: 160vh;
    }
    .WhatWeDoParagraph {
        font-size: 1.0em;
        /*  */
        width: calc(80% + 1.5vw);
    }
    .WhatWeDoGrid {
        width: 80%;
        background-color: rgba(36, 36, 36, 0);
    }
    .WhatWeDoGridMember {
        margin-top: 5%;
    }
    .WhatWeDoGridMember1 {
        border-radius: 10px 10px 10px 10px;
        margin-top: 0%;
    }
    .WhatWeDoGridMember2 {
        border-radius: 10px 10px 10px 10px;
    }
    .WhatWeDoGridMember3 {
        border-radius: 10px 10px 10px 10px;
    }
    .WhatWeDoGridMember4 {
        border-radius: 10px 10px 10px 10px;
    }
}

/* TODO start here */
@media screen and (max-width:490px) {}