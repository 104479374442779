.FooterParent {
    font-family: Inter;
    font-style: normal;
    /*  */
    background-color: rgba(18, 18, 18, 1);
    width: 100%;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    /*  */
    margin: 0%;
    padding: 0%;
}

.FooterHead {
    font-size: 1.8em;
    font-weight: bold;
}

.Links {
    padding-left: 7% !important;
    /* color: rgb(76, 148, 25); */
    /* background-color: red; */
}

/* .LinksMemberUl {
    background-color: chartreuse;
    color: rgb(76, 148, 25) !important;
} */

/* .LinksMember {
    background-color: brown;
    color: rgb(76, 148, 25);
} */

.HorizontalLine {
    color: white;
    border: none;
}

.FooterBottom {
    margin: auto;
    padding: 0%;
    padding-bottom: 2%;
    /* height:100px; */
    /*  */
    /* background-color: red; */
    width: 100vw !important;
    /*  */
    /* display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center; */
}

.FooterBottomRow {
    /* background-color: green; */
    width: 100%;
    /*  */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* padding-left:0% !important; */
}

.FooterBottomParagraph {
    padding: 0%;
    margin: 0%;
}

/* .FooterBottomParagraph1{
    background-color:blue !important;
    margin-left:0% !important;
} */

.FooterBottomIconDiv {
    /* background-color: red; */
    /*  */
    width: 50%;
    padding-left: 0px !important;
    /*  */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*  */
    font-size: 18px;
}

.bottomMostLinks{
    margin: 0%;
    padding:0%;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .Links {
        padding-left: 3% !important;
    }
    .FooterBottomIconDiv {
        width: 80%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .FooterExtraSpace {
        display: none;
    }
    .Links {
        padding-left: 3% !important;
    }
    .FooterBottomIconDiv {
        width: 150%;
    }
}

/*! sm */

@media screen and (min-width:576px) and (max-width:767px) {
    .FooterExtraSpace {
        display: none;
    }
    .Links {
        padding-left: 0% !important;
        /* background-color: green; */
    }
    .LinksMemberUl {
        list-style-type: none;
        /* display: inline; */
        /* background-color: red; */
        /*  */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0% 8%;
        padding-top: 2%;
    }
    .LinksMember {
        display: inline;
    }
    .HorizontalLine {
        width: 90%;
        margin: 2% auto;
        margin-bottom: 4%;
    }
    .FooterBottom {
        width: 120vw !important;
    }
    .FooterBottomRow {
        margin: auto;
        /* display: flex;
        flex-direction: column;
        align-items: center; */
        width: 200% !important;
    }
    /* .FooterBottomParagraph2 {
        float: right;
        margin-right: 20% !important;
    } */
    .FooterBottomIconDiv {
        /* float: right; */
        width: 20%;
    }
}

/*! xsm */

@media screen and (max-width:575px) {
    .FooterExtraSpace {
        display: none;
    }
    .Links {
        padding-left: 0% !important;
    }
    .HorizontalLine {
        width: 90%;
        margin: 2% auto;
        margin-bottom: 4%;
    }
    .FooterBottom {
        width: 100vw !important;
    }
    .FooterBottomRow {
        /* margin: auto;
        width: 200% !important; */
        padding-left: 2%;
    }
    .FooterBottomParagraph2 {
        margin-top: 1% !important;
    }
    .FooterBottomIconDiv {
        width: 20%;
        margin-top: 2% !important;
    }
}

@media screen and (min-width: 490px) and (max-width:575px) {
    .LinksMemberUl {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0% 8%;
        padding-top: 2%;
    }
    .LinksMember {
        display: inline;
    }
}

@media screen and (max-width: 489px) {
    .FooterBottomIconDiv {
        width: 40%;
    }
}