.ContactParent {
    font-family: Inter;
    font-style: normal;
    /*  */
    margin: 0%;
    padding: 0%;
    /*  */
    /* background-color: darkmagenta; */
    /*  */
    min-height: 60vh;
    /*  */
    color: white;
}
.ContactHead {
    margin-top: 4%;
    color: rgba(107, 199, 39, 1);
    font-size: calc(1.5em + 1.0vw);
    /* font-size: 2.5em; */
    font-weight: bold;
}

.ContactParagraph {
    font-size: calc(1em + 0.2vw);
    text-align: center;
    margin: 0%;
    padding: 0%;
}