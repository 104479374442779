.AboutUsParent {
    font-family: Inter;
    font-style: normal;
    /*  */
    margin: 0%;
    margin-top: 5%;
    padding: 0% 5%;
    /*  */
    /* background-color: darkgoldenrod; */
    /*  */
    min-height: 110vh;
    /*  */
    color: white;
}

.AboutUsHead {
    color: rgba(107, 199, 39, 1);
    /*  */
    font-size: calc(1.5em + 1.0vw);
    font-weight: bold;
}

.AboutUsParagraph {
    /* width: 70%; */
    /*  */
    font-size: calc(1em + 0.2vw);
    /* font-size: 1em; */
    text-align: center;
}

.AboutUsVideo {
    width: calc(65% + 1.5vw);
    /* width: 65%; */
}

/*! lg */

@media screen and (min-width:992px) and (max-width: 1199px) {
    .AboutUsParent {
        min-height: 60vh;
        /* height: auto; */
        /* margin-top: 5%; */
    }
    /* .AboutUsHead {
        font-size: calc(2.5em + 1.5vw);
        font-size: 2.5em;
    } */
    /* .AboutUsParagraph {
        font-size: calc(1em + 1.5vw);
        font-size: 1em;
        text-align: justify;
    } */
    .AboutUsVideo {
        width: calc(90% + 1.5vw);
        /* width: 65%; */
    }
}

/*! md */

@media screen and (min-width:768px)and (max-width:991px) {
    .AboutUsParent {
        min-height: 60vh;
        /* height: auto; */
        /* margin-top: 5%; */
    }
    .AboutUsVideo {
        width: calc(90% + 1.5vw);
        /* width: 65%; */
    }
}

/*! sm */

@media screen and (min-width:576px) and (max-width:767px) {
    .AboutUsParent {
        min-height: 65vh;
        /* margin-top:5%; */
    }
    .AboutUsParagraph {
        /* text-align: left; */
        width: calc(90% + 1.5vw);
    }
    .AboutUsVideo {
        width: calc(90% + 1.5vw);
    }
}

/*! xsm */

@media screen and (max-width:575px) {
    .AboutUsParent {
        min-height: 80vh;
    }
    .AboutUsParagraph {
        text-align: justify;
        font-size: 1.0em;
        /*  */
        width: calc(90% + 1.5vw);
    }
    .AboutUsVideo {
        width: calc(90% + 1.5vw);
    }
}