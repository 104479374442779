.WelcomeParent {
    font-family: Inter;
    font-style: normal;
    /*  */
    /* background-color: cyan; */
    /*  */
    background-image: url("./assets/welcome_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    padding-top: 10%;
    padding-bottom: 10%;
}

.WelcomeText {
    /* background-color: blue; */
    color: white;
}

.WelcomeTextSpan {
    /* background-color: blue; */
    color: rgba(107, 199, 39, 1);
}

.WelcomeTextParagraph {
    /* background-color: blue; */
    font-size: 1.5em;
}

.WelcomeBtn {
    margin: 0%;
    /* margin-bottom: 10%; */
    /*  */
    /* background: rgba(107, 199, 39, 0.8); */
    border-radius: 5px;
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .WelcomeParent {
        padding-bottom: 15%;
    }
}

@media screen and (min-width:768px) and (max-width:991px) {
    .WelcomeParent {
        padding-bottom: 25%;
    }
}

@media screen and (min-width:576px) and (max-width:767px) {
    
}
@media screen and (max-width:575px) {

}